import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'

const InvalidManageServices = {

  /**
   * 获取无效原因
   */
  async getReasonList(params){
    
    const result = await request.post(`${SERVER.BASE_DATA_SERVICE }/api/v1/invalidClue/list`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  },
}

export default InvalidManageServices